@import "~styles/variables.less";

.home {
	text-align: center;
	h1 {
		font-size: 2rem;
	}
	ul {

		list-style: none;
		padding: 0;

		a {
			color: inherit;
			text-decoration: none;
		}

		.li2 {
			margin: 1rem 0;
			font-family: "Source Sans Pro", Georgia, serif;
			color: white;
			position: relative;
			background-size: cover;
			background-position: center;
			border-radius: 3px;
			overflow: hidden;
			@shadow-size: 4px;
			box-shadow: 0 @shadow-size / 4 @shadow-size #eee;
			&:hover {
				opacity: 0.8;
			}

			&__link {
				display: block;
				padding: 1.5rem 2rem;
				font-size: 1.75rem;
				font-weight: bold;
			}

			&--overlay {
				.li2__link {
					background-color: #00000050;
				}
			}

			&__icon {
				height: 0.8em;
				margin-right: 0.2em;
			}
		}
	}
}
