header {
  grid-area: nav;
  display: grid;
  grid-template-columns: auto 600px auto;
}
@media (max-width: 600px) {
  header {
    grid-template-columns: 0 100% 0;
  }
}
header nav {
  grid-column: 2 / 3;
  text-align: center;
  border-bottom: 1px solid #e9e9e9;
}
header nav img {
  padding: 1.8rem 0;
  height: 12rem;
  text-align: center;
}
header nav ul {
  list-style: none;
  margin: calc(12rem / 3) 0;
  padding: 0;
}
header nav ul li {
  display: inline-block;
}
header nav ul li:not(:last-child)::after {
  content: "|";
  margin: 0.3rem;
}
