@import "~styles/variables.less";

header {
	grid-area: nav;

	display: grid;
	grid-template-columns: @grid-columns;

	.tablet({
		grid-template-columns: 0 100% 0;
	});

	nav {
		grid-column: ~"2 / 3";
		text-align: center;
		border-bottom: 1px solid #e9e9e9;

		img {
			padding: (@header-height * 0.15) 0;
			height: @header-height;
			text-align: center;
		}

		ul {
			list-style: none;
			margin: calc(@header-height / 3) 0;
			padding: 0;

			li {
				display: inline-block;
				&:not(:last-child)::after {
					content: "|";
					margin: 0.3rem;
				}
			}
		}
	}
}
