.redirect {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;

	text-align: center;

	.redirect__img {
		max-width: 400px;
		width: 100%;
		display: block;
	}
}
