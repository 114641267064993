.home {
  text-align: center;
}
.home h1 {
  font-size: 2rem;
}
.home ul {
  list-style: none;
  padding: 0;
}
.home ul a {
  color: inherit;
  text-decoration: none;
}
.home ul .li2 {
  margin: 1rem 0;
  font-family: "Source Sans Pro", Georgia, serif;
  color: white;
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1px 4px #eee;
}
.home ul .li2:hover {
  opacity: 0.8;
}
.home ul .li2__link {
  display: block;
  padding: 1.5rem 2rem;
  font-size: 1.75rem;
  font-weight: bold;
}
.home ul .li2--overlay .li2__link {
  background-color: #00000050;
}
.home ul .li2__icon {
  height: 0.8em;
  margin-right: 0.2em;
}
