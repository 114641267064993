@header-height: 12rem;
@max-width: 600px;
@grid-columns: auto @max-width auto;
@main-color: #444;
@background-color: #FBFBFF;

@color-main: #108EE9;

.tablet(@rules) {
	@media (max-width: @max-width) {
		@rules();
	}
}
