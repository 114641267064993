@import "~normalize.css";
@import "./variables.less";

* {
	box-sizing: border-box;
}

html, body {
	height: 100%;
	overflow-y: overlay;
}

body {
	font-family: sans-serif;
	color: @main-color;
	background-color: @background-color;
}

button, input {
	padding: 0.5em;
	background-color: @background-color;
	outline: none;
	border: 2px solid @main-color;
}

button, input, table, fieldset {
	border-radius: 0.25em;
}

#root {
	display: grid;
	width: 100%;
	height: 100%;
	grid-template-areas:
		"nav  nav    nav"
		"left main   right"
		"left footer right";
	grid-template-rows: auto 1fr 4rem;
	grid-template-columns: @grid-columns;

	.tablet({
		grid-template-columns: 0 100% 0;
	})
}
