@import "~styles/variables.less";

main {
	grid-area: main;
}

footer {
	grid-area: footer;
	span {
		display: inline-block;
		margin-top: 0.5rem;
	}
}

nav, main, footer {
	padding: 0 20px;
}
