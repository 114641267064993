@import "~normalize.css";
* {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  overflow-y: overlay;
}
body {
  font-family: sans-serif;
  color: #444;
  background-color: #FBFBFF;
}
button,
input {
  padding: 0.5em;
  background-color: #FBFBFF;
  outline: none;
  border: 2px solid #444;
}
button,
input,
table,
fieldset {
  border-radius: 0.25em;
}
#root {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-areas: "nav  nav    nav" "left main   right" "left footer right";
  grid-template-rows: auto 1fr 4rem;
  grid-template-columns: auto 600px auto;
}
@media (max-width: 600px) {
  #root {
    grid-template-columns: 0 100% 0;
  }
}
